import './App.css';
import './custom.css'
import { useEffect, useState, useContext } from 'react';
import { FaceLandmarker, FaceLandmarkerOptions, FilesetResolver } from "@mediapipe/tasks-vision";
import { Color, Euler, Matrix4 } from 'three';
import { Canvas, useFrame, useGraph } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { useDropzone } from 'react-dropzone';
import LavniLogo from "./images/logo.png";
import MaleAvatar from "./images/avatars/a-one-t.jpg";
import FemaleAvatar from "./images/avatars/a-two-t.jpg";

let video: HTMLVideoElement;
let faceLandmarker: FaceLandmarker;
let lastVideoTime = -1;
let blendshapes: any[] = [];
let rotation: Euler;
let headMesh: any[] = [];

const MALE_AVATAR = "https://models.readyplayer.me/64be67bc4e1697f144f113c1.glb?morphTargets=ARKit&textureAtlas=1024";
const FEMALE_AVATAR = "https://models.readyplayer.me/64be760d3e25313d23fe1f3f.glb?morphTargets=ARKit&textureAtlas=1024";

const options: FaceLandmarkerOptions = {
  baseOptions: {
    modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
    delegate: "GPU"
  },
  numFaces: 1,
  runningMode: "VIDEO",
  outputFaceBlendshapes: true,
  outputFacialTransformationMatrixes: true,
};

function Avatar({ url, setIsLoadingAvatar }: { url: string, setIsLoadingAvatar: any }) {
  const { scene } = useGLTF(url);
  const { nodes } = useGraph(scene);

  useEffect(() => {
    if (nodes.Wolf3D_Head) headMesh.push(nodes.Wolf3D_Head);
    if (nodes.Wolf3D_Teeth) headMesh.push(nodes.Wolf3D_Teeth);
    if (nodes.Wolf3D_Beard) headMesh.push(nodes.Wolf3D_Beard);
    if (nodes.Wolf3D_Avatar) headMesh.push(nodes.Wolf3D_Avatar);
    if (nodes.Wolf3D_Head_Custom) headMesh.push(nodes.Wolf3D_Head_Custom);

    setIsLoadingAvatar(false);
  }, [nodes, url]);

  useFrame(() => {
    if (blendshapes.length > 0) {
      blendshapes.forEach(element => {
        headMesh.forEach(mesh => {
          let index = mesh.morphTargetDictionary[element.categoryName];
          if (index >= 0) {
            mesh.morphTargetInfluences[index] = element.score;
          }
        });
      });

      nodes.Head.rotation.set(rotation.x, rotation.y, rotation.z);
      nodes.Neck.rotation.set(rotation.x / 5 + 0.3, rotation.y / 5, rotation.z / 5);
      nodes.Spine2.rotation.set(rotation.x / 10, rotation.y / 10, rotation.z / 10);
    }
  });

  return <primitive object={scene} position={[0, -1.75, 3]} />
}

function App() {
  const [url, setUrl] = useState<string>("https://models.readyplayer.me/64be67bc4e1697f144f113c1.glb?morphTargets=ARKit&textureAtlas=1024");
  // const [url, setUrl] = useState<string>("https://models.readyplayer.me/64e73ace24531d24323cdde2.glb");
  const [selectedAvatar, setSelectedAvatar] = useState<string>("");
  const [isLoadingAvatar, setIsLoadingAvatar] = useState<boolean>(false);

  const { getRootProps } = useDropzone({
    onDrop: files => {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = () => {
        setUrl(reader.result as string);
      }

      reader.readAsDataURL(file);
    }
  });

  const setup = async () => {
    console.log('AAA');
    const filesetResolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm");
    faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, options);

    console.log(filesetResolver);
    console.log(faceLandmarker);

    video = document.getElementById("video") as HTMLVideoElement;

    console.log('BBB');
    console.log(video);

    navigator.mediaDevices.getUserMedia({
      video: { width: 1280, height: 720 },
      audio: false,
    }).then(function (stream) {
      console.log(stream);
      video.srcObject = stream;
      video.addEventListener("loadeddata", predict);
    });
  }

  const predict = async () => {
    try {
      console.log('CCCC');

      let nowInMs = Date.now();
      
      console.log('lastVideoTime', lastVideoTime);
      console.log('video.currentTime', video.currentTime);

      if (lastVideoTime !== video.currentTime) {
        console.log('DDDD');
        lastVideoTime = video.currentTime;
        const faceLandmarkerResult = faceLandmarker.detectForVideo(video, nowInMs);

        if (faceLandmarkerResult.faceBlendshapes && faceLandmarkerResult.faceBlendshapes.length > 0 && faceLandmarkerResult.faceBlendshapes[0].categories) {
          blendshapes = faceLandmarkerResult.faceBlendshapes[0].categories;

          const matrix = new Matrix4().fromArray(faceLandmarkerResult.facialTransformationMatrixes![0].data);
          rotation = new Euler().setFromRotationMatrix(matrix);

          console.log('EEE');
        }
      }

      window.requestAnimationFrame(predict);
    } catch (error) {
      console.log('Error');
      console.log(error);
    }
  }

  const handleOnChange = (event: any) => {
    setUrl(`${event.target.value}?morphTargets=ARKit&textureAtlas=1024`);
  }
  
  const changeAvatar = (event: any) => {
    setIsLoadingAvatar(true);

    const id = event.currentTarget.id;

    if(selectedAvatar != id) {
        const num = Math.floor(Math.random() * (10000000 - 100000 + 1)) + 100000;

        if (id == "male-avatar") {
          setUrl(MALE_AVATAR + "&num=" + num);
        } else {
          setUrl(FEMALE_AVATAR + "&num=" + num);
        }
    }
  }

  useEffect(() => {
    setup();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-5 col-sm-12">
          <div className="sub_sec1">
              <div className="sub_sec1_s1">
                  <img className="lavni-logo" src={LavniLogo} alt="Lavni Logo"/>
              </div>
              
              <div className="sub_sec1_s2">
                  <img className={"three-d-avatar-image highlight-image " + (selectedAvatar == "female-avatar" ? "active" : "")} src={FemaleAvatar} onClick={changeAvatar} id="female-avatar"/>

                  <div className="space"></div>

                  <img className={"three-d-avatar-image highlight-image " + (selectedAvatar == "male-avatar" ? "active" : "")} src={MaleAvatar} onClick={changeAvatar} id="male-avatar"/>
              </div>
          </div>
        </div>

        <div className="col-md-7 col-sm-12">
          <div className="sub_sec2">
            <div className="avatar-div">               
                <div {...getRootProps({ className: 'dropzone' })}>
                    <p className='drop-text'>Drag & drop RPM avatar GLB file here</p>
                </div>

                <input className='url' type="text" placeholder="Paste RPM avatar URL" onChange={handleOnChange} />

                <video className='camera-feed' id="video" autoPlay></video>

                <Canvas style={{ height: 600 }} camera={{ fov: 25 }} shadows>
                  <ambientLight intensity={0.5} />
                  <pointLight position={[10, 10, 10]} color={new Color(1, 1, 0)} intensity={0.5} castShadow />
                  <pointLight position={[-10, 0, 10]} color={new Color(1, 0, 0)} intensity={0.5} castShadow />
                  <pointLight position={[0, 0, 10]} intensity={0.5} castShadow />
                  <Avatar url={url} setIsLoadingAvatar={setIsLoadingAvatar} />
                </Canvas>

                {
                  isLoadingAvatar && <div className='loading-avatar'>Loading Avatar...</div>
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;